// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import SmileOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/SmileOutlined';
import UserOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/UserOutlined';
import MessageOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/MessageOutlined';
import TableOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/TableOutlined';
import HistoryOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/HistoryOutlined';
import NotificationOutlined from '/opt/buildhome/repo/node_modules/@ant-design/icons/es/icons/NotificationOutlined';
export default { SmileOutlined, UserOutlined, MessageOutlined, TableOutlined, HistoryOutlined, NotificationOutlined };
